import {Injectable} from '@angular/core';
import {LogItem} from './LogItem';
import {BehaviorSubject} from 'rxjs';

export enum LogLevels {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal'
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private items$ = new BehaviorSubject<LogItem>({
    info: {},
    level: LogLevels.info,
    message: 'Init message'
  });

  public logItems$(): BehaviorSubject<LogItem> {
    return this.items$;
  }

  public debug(message: string, info: { [p: string]: string }): void {
    this.log(LogLevels.debug, message, info);
  }

  public info(message: string, info: { [p: string]: string }): void {
    this.log(LogLevels.info, message, info);
  }

  public warn(message: string, info: { [p: string]: string }): void {
    this.log(LogLevels.warn, message, info);
  }

  public error(message: string, info: { [p: string]: string }): void {
    this.log(LogLevels.error, message, info);
  }

  public fatal(message: string, info: { [p: string]: string }): void {
    this.log(LogLevels.fatal, message, info);
  }

  private log(level: LogLevels, message: string, info: { [p: string]: string }): void {
    this.items$.next({
      message,
      level,
      info
    });
  }
}
