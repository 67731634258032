import { Component, Input, OnInit } from '@angular/core';
import {ILocaleDict} from '../../../locale/ILocaleDict';
import {TranslationProvider} from '../../../locale/TranslationProvider';

enum SpinnerType {
  DEFAULT = 'default',
  PAY_U = 'PayU',
  GO_PAY = 'GoPay',
  GPE = 'GPE',
  COMGATE = 'Comgate'
}

@Component({
  selector: "app-spinner-neutral",
  templateUrl: "./spinner-neutral.component.html",
  styleUrls: ["./spinner-neutral.component.scss"]
})
export class SpinnerNeutralComponent implements OnInit {
  @Input() spinnerType = 'default';

  public spinnerTypes = SpinnerType;

  get translation(): ILocaleDict {
    return this.translationProvider.getTranslation();
  }

  constructor(public translationProvider: TranslationProvider) {
  }

  ngOnInit(): void {
    if (!(Object.values(SpinnerType) as string[]).includes(this.spinnerType)) {
      this.spinnerType = SpinnerType.DEFAULT;
    }
  }
}
