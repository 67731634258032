import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SpinnerNeutralComponent } from './spinner-neutral/spinner-neutral.component';
import { SpinnerGpeComponent } from './spinner-gpe/spinner-gpe.component';

@NgModule({
  declarations: [
    SpinnerNeutralComponent,
    SpinnerGpeComponent
  ],
  exports: [
    SpinnerNeutralComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage
  ]
})
export class ComponentsModule {
}
