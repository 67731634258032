import {Injectable} from '@angular/core';
import {ILocaleDict} from './ILocaleDict';
import {translation as cs} from './cs.locale';

@Injectable()
export class TranslationProvider {

  private locale = 'cs';

  private availableTranslations: { [id: string]: ILocaleDict; } = {};

  constructor() {
    this.availableTranslations[this.locale] = cs;
  }

  public getTranslation(): ILocaleDict {
    return this.availableTranslations[this.locale];
  }
}
