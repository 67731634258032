<div [ngClass]="[spinnerTypes.GO_PAY === spinnerType?'parent parentGoPay':'parent']">
  <div [ngSwitch]="spinnerType" [ngClass]="[spinnerTypes.GO_PAY === spinnerType?'block blockGoPay':'block']">
    <img *ngSwitchCase="spinnerTypes.PAY_U" ngSrc="assets/spinners/payu-spinner.svg" alt="PayU spinner"
         height="40" width="40" />
    <img *ngSwitchCase="spinnerTypes.GO_PAY" ngSrc="assets/spinners/gopay-spinner.svg" alt="GoPay spinner"
         height="100" width="100" />
    <app-spinner-gpe *ngSwitchCase="spinnerTypes.GPE"></app-spinner-gpe>
    <img *ngSwitchCase="spinnerTypes.COMGATE" ngSrc="assets/spinners/comgate-spinner.svg" alt="Comgate spinner"
         width="52" height="52" />
    <img *ngSwitchCase="spinnerTypes.DEFAULT" ngSrc="assets/spinners/default-spinner.svg" alt="default spinner"
         height="50" width="50" />
    <img *ngSwitchDefault ngSrc="assets/spinners/default-spinner.svg" alt="default spinner"
         height="50" width="50" />
  </div>
  <div class="underSpinner" *ngIf="spinnerTypes.DEFAULT === spinnerType">
    <div class="item">{{ translation.spinner_neutral_description }}</div>
    <div class="item">{{ translation.spinner_neutral_description_en }}</div>
  </div>
</div>
