import { ILocaleDict } from './ILocaleDict';

export const translation: ILocaleDict = {
  pages: {
    error: {
      codes: {
        'code-default': {
          description1: 'Unknown error',
          description2: 'Please close this tab.'
        },
        'code-309': {
          description1: 'Page has expired',
          description2: 'The connection is no longer valid. If you have a payment opened in another browser tab, please complete the payment there. Please close this tab.'
        },
        'code-703': {
          description1: 'Processing error at bank',
          description2: 'The used payer\'s account was rejected by the bank. Please close this tab.'
        },
        'code-705': {
          description1: 'Processing error at bank',
          description2: 'The payment was rejected by the bank due to the wrong account currency. Please close this tab.'
        },
        'code-706': {
          description1: 'Processing error at bank',
          description2: 'The payment was rejected by the bank due to the wrong currency of the payer\'s account. Please close this tab.'
        },
        'code-707': {
          description1: 'Processing error at bank',
          description2: 'The bank rejected the payment due to the wrong account type. Please close this tab.'
        },
        'code-712': {
          description1: 'Error processing at bank',
          description2: 'An account with an unsupported currency was used for payment. Please close this tab.'
        },
        'code-740': {
          description1: 'Processing error at bank',
          description2: 'Payer and beneficiary account numbers cannot be identical. The payment was rejected by the bank. Please close this tab.'
        },
        'code-753': {
          description1: 'Processing error at bank',
          description2: 'There are insufficient funds in the account. Please close this tab.'
        }
      }
    },
  },
  spinner_neutral_description: 'Komunikujeme s Vaší bankou',
  spinner_neutral_description_en: 'Connecting to your bank'
};

