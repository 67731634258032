import { Injectable } from '@angular/core';
import { FlowProperties } from './flow/IFlowProperties';
import { LocalSessionDto, LocalSessionsDto } from './LocalSessionsDto';
import { addWeeks, isPast, parseJSON } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class FlowStorageService {

  /**
   * Check if session in session storage is same like the latest one in localStorage.
   */
  static checkActualSession(flowId: string): boolean {
    const sessions = JSON.parse(localStorage.getItem('sessions') || '{"sessions":{}}') as LocalSessionsDto;

    const sessionGlobal = sessions.sessions[flowId] || null;
    if (sessionGlobal === null) {
      return true;
    } else {
      const sessionLocal = sessionStorage.getItem('jwt');
      return sessionLocal === sessionGlobal.sessionHash;
    }
  }

  private static clearOldTokens(): LocalSessionsDto {
    const sessions = JSON.parse(localStorage.getItem('sessions') || '{"sessions":{}}') as LocalSessionsDto;

    for (const session of Object.keys(sessions.sessions)) {
      const timestamp = sessions.sessions[session].timestamp;
      if (timestamp) {
        const dateValidTo = parseJSON(timestamp);
        if (isPast(dateValidTo)) {
          delete sessions.sessions[session];
        }
      } else {
        delete sessions.sessions[session];
      }
    }

    return sessions;
  }

  public saveTokenToSession(token: string): void {
    sessionStorage.setItem('jwt', token);
  }

  public saveTokenToSessions(token: string, flowId: string): void {
    const sessions = FlowStorageService.clearOldTokens();
    sessions.sessions[flowId] = {
      flowId,
      sessionHash: token,
      timestamp: addWeeks(new Date(), 1).toISOString()
    } as LocalSessionDto;
    localStorage.setItem('sessions', JSON.stringify(sessions));
  }

  public saveFlowProperties(data: FlowProperties): void {
    if (!data) {
      return;
    }
    if (data.merchant_redirect_url) {
      sessionStorage.setItem('merchant_redirect_url', data.merchant_redirect_url);
    }
    if (data.merchant_shopping_cart_url) {
      sessionStorage.setItem('merchant_shopping_cart_url', data.merchant_shopping_cart_url);
    }
    if (data.flowId) {
      sessionStorage.setItem('flowId', data.flowId);
    }
    if (data.show_return_to_shopping_cart) {
      const strValue = String(data.show_return_to_shopping_cart);
      sessionStorage.setItem('show_return_to_shopping_cart', strValue);
    }
    if (data.flow_session_expiration) {
      sessionStorage.setItem("flow_session_expiration", data.flow_session_expiration);
    }
  }

  public getFlowProperties(): FlowProperties {
    return {
      merchant_redirect_url: sessionStorage.getItem("merchant_redirect_url") || undefined,
      merchant_shopping_cart_url: sessionStorage.getItem("merchant_shopping_cart_url") || undefined,
      flowId: sessionStorage.getItem("flowId") || undefined,
      flow_session_expiration: sessionStorage.getItem("flow_session_expiration") || undefined,
      show_return_to_shopping_cart: sessionStorage.getItem('show_return_to_shopping_cart') === 'true',
    };
  }
}
