<div id="loader-wrapper">
  <div id="loader">
    <div id="loader-circle-0" class='loader-circle'>
      <div id="loader-circle-1" class='loader-circle'>
        <div id="loader-circle-2" class='loader-circle'>
          <div id="loader-circle-3" class='loader-circle'></div>
        </div>
      </div>
    </div>
  </div>
</div>
