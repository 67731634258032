import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner-gpe',
  templateUrl: './spinner-gpe.component.html',
  styleUrls: ['./spinner-gpe.component.scss']
})
export class SpinnerGpeComponent {

}
